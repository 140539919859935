import _ from 'lodash';

export const HandlerDataMenu = (menudata, permission = {}) => {
  let result = [];
  const findMenu = (items, id, arr) => {
    if (!items) return;
    items.forEach((item) => {
      if (item.Id === id) {
        item.Children = arr;
      } else if (item.Children) {
        findMenu(item.Children, id, arr);
      }
    });
  };

  const getMenu = (id) => {
    let childrens = [];
    menudata.forEach((report) => {
      if (report.ParentId === id) {
        childrens.push(report);
      }
    });
    if (
      [
        '3d96ea8f-7a7c-46f3-adba-98975ab0c484',
        '129e6752-d742-4e63-afc9-f118d9e06e06',
        '74a87bef-dbc3-44e9-89ca-059663a4fb00'
      ].includes(id)
    ) {
      let name;
      switch (id) {
        default:
          break;
        case '3d96ea8f-7a7c-46f3-adba-98975ab0c484':
          name = 'TTML';
          break;
        case '129e6752-d742-4e63-afc9-f118d9e06e06':
          name = 'Province';
          break;
        case '74a87bef-dbc3-44e9-89ca-059663a4fb00':
          name = 'District';
          break;
      }
      let obj = [{ [name + '-kqi-chart']: name + ' Chart' }];
      obj.forEach((m) => {
        childrens.unshift({
          Checked: false,
          Id: Object.keys(m)[0],
          IsParent: false,
          Level: 2,
          Name: m[Object.keys(m)[0]],
          ParentCount: 1,
          ParentId: _.noop(),
          Path: 'KQI/' + name + '/' + m[Object.keys(m)[0]],
          ReportGroups: _.noop(),
          TypeName: 'Custom',
          hardcode: !0
        });
      });
    }
    if (id === '2dac863e-0866-4516-80fa-225572a1f027') {
      childrens.unshift({
        Checked: false,
        Id: 'kh-vip-bcn-chart',
        IsParent: false,
        Level: 2,
        Name: 'Rank Chart',
        ParentCount: 1,
        ParentId: _.noop(),
        Path: 'BC Nhanh/KHVIP/Rank Chart',
        ReportGroups: _.noop(),
        TypeName: 'Custom',
        hardcode: !0
      });
    }
    if (id === 'ab03217b-eb92-43e7-99cd-5db849604d3e') {
      childrens.unshift({
        Checked: false,
        Id: 'ims-chart',
        IsParent: false,
        Level: 2,
        Name: 'Chart',
        ParentCount: 1,
        ParentId: _.noop(),
        Path: 'BC Nhanh/ims/Chart',
        ReportGroups: _.noop(),
        TypeName: 'Custom',
        hardcode: !0
      });
    }
    if (id === '9cde7472-6338-4dbd-853a-5c2c836f5abe') {
      childrens.unshift({
        Checked: false,
        Id: 'cscore-chart',
        IsParent: false,
        Level: 2,
        Name: 'Chart',
        ParentCount: 1,
        ParentId: _.noop(),
        Path: 'BC Nhanh/CSCORE/Chart',
        ReportGroups: _.noop(),
        TypeName: 'Custom',
        hardcode: !0
      });
    }
    if (id === '3d358282-1010-40f7-9ceb-dad052b964fe') {
      childrens.unshift({
        Checked: false,
        Id: 'sgsn-chart',
        IsParent: false,
        Level: 2,
        Name: 'Chart',
        ParentCount: 1,
        ParentId: _.noop(),
        Path: 'BC Nhanh/SGSN/Chart',
        ReportGroups: _.noop(),
        TypeName: 'Custom',
        hardcode: !0
      });
    }
    if (id === '5576b40c-568e-4a99-97c4-2c3b35d3ab3a') {
      childrens.unshift({
        Checked: false,
        Id: 'ggsn-chart',
        IsParent: false,
        Level: 2,
        Name: 'Chart',
        ParentCount: 1,
        ParentId: _.noop(),
        Path: 'BC Nhanh/GGSN/Chart',
        ReportGroups: _.noop(),
        TypeName: 'Custom',
        hardcode: !0
      });
    }
    if (id === 'b86b9a87-951c-4fd8-a73a-c0cfe6c5704e') {
      childrens.unshift({
        Checked: false,
        Id: 'smsc-chart',
        IsParent: false,
        Level: 2,
        Name: 'SMSC Chart',
        ParentCount: 1,
        ParentId: _.noop(),
        Path: 'BC Nhanh/VAS/SMSC Chart',
        ReportGroups: _.noop(),
        TypeName: 'Custom',
        hardcode: !0
      });
    }
    if (id === 'b86b9a87-951c-4fd8-a73a-c0cfe6c5704e') {
      childrens.unshift({
        Checked: false,
        Id: 'smscb-chart',
        IsParent: false,
        Level: 2,
        Name: 'SMSCB Chart',
        ParentCount: 1,
        ParentId: _.noop(),
        Path: 'BC Nhanh/VAS/SMSCB Chart',
        ReportGroups: _.noop(),
        TypeName: 'Custom',
        hardcode: !0
      });
    }
    if (id === 'ed6db744-37a8-43ce-af82-4643fbac6019') {
      childrens.unshift({
        Checked: false,
        Id: 'in-chart',
        IsParent: false,
        Level: 2,
        Name: 'IN Chart',
        ParentCount: 1,
        ParentId: _.noop(),
        Path: 'BC Nhanh/IN/IN Chart',
        ReportGroups: _.noop(),
        TypeName: 'Custom',
        hardcode: !0
      });
    }
    ///ffc2877d-a8c2-44e5-b515-6691498881fe
    if (id === 'ffc2877d-a8c2-44e5-b515-6691498881fe') {
      childrens.push({
        Checked: false,
        Id: 'site-ha-tang',
        IsParent: false,
        Level: 2,
        Name: 'Cập nhật Site hạ tầng',
        ParentCount: 1,
        ParentId: _.noop(),
        Path: 'Site_HATANG/Cập nhật Site hạ tầng',
        ReportGroups: _.noop(),
        TypeName: 'Custom',
        hardcode: !0
      });
      childrens.push({
        Checked: false,
        Id: 'dim-ip-sitetoiuu',
        IsParent: false,
        Level: 2,
        Name: 'Cập nhật phân lớp trạm',
        ParentCount: 1,
        ParentId: _.noop(),
        Path: 'Truyền dẫn/Cập nhật Site tối ưu',
        ReportGroups: _.noop(),
        TypeName: 'Custom',
        hardcode: !0
      });
    }

    // Truyền dẫn
    if (id === 'e577d475-c043-4ffe-8442-20f48b8f041b') {
      childrens.push({
        Checked: false,
        Id: 'dim-tram',
        IsParent: false,
        Level: 2,
        Name: 'Cập nhật BW Trạm',
        ParentCount: 1,
        ParentId: _.noop(),
        Path: 'Truyền dẫn/Cập nhật BW Trạm',
        ReportGroups: _.noop(),
        TypeName: 'Custom',
        hardcode: !0
      });

      /** Báo cáo CSG */
      childrens.push({
        Checked: false,
        Id: 'dim-csg',
        IsParent: false,
        Level: 2,
        Name: 'Cập nhật Thiết bị FTTX',
        ParentCount: 1,
        ParentId: _.noop(),
        Path: 'Truyền dẫn/Cập nhật Thiết bị FTTX',
        ReportGroups: _.noop(),
        TypeName: 'Custom',
        hardcode: !0
      });
      // Báo cáo Internet
      childrens.push({
        Checked: false,
        Id: 'dim-internet',
        IsParent: false,
        Level: 2,
        Name: 'Cập nhật kênh Internet',
        ParentCount: 1,
        ParentId: _.noop(),
        Path: 'Truyền dẫn/Cập nhật kênh Internet',
        ReportGroups: _.noop(),
        TypeName: 'Custom',
        hardcode: !0
      });

    }


    if (id == '6e939295-84fa-4522-b2a4-18354387266a') {
      childrens.push({
        Checked: false,
        Id: 'colocation',
        IsParent: false,
        Level: 2,
        Name: 'Colocation',
        ParentCount: 1,
        ParentId: _.noop(),
        Path: '/noc-sopirs/91.CSHT/Colocation',
        ReportGroups: _.noop(),
        TypeName: 'Custom',
        hardcode: !0
      });
    }
    // if (id === 'e577d475-c043-4ffe-8442-20f48b8f041b') {
    //   childrens.push({
    //     Checked: false,
    //     Id: 'dim-ip-core',
    //     IsParent: false,
    //     Level: 2,
    //     Name: 'Cập nhật link IPCORE-IPRAN',
    //     ParentCount: 1,
    //     ParentId: _.noop(),
    //     Path: 'Truyền dẫn/Cập nhật link IPCORE-IPRAN',
    //     ReportGroups: _.noop(),
    //     TypeName: 'Custom',
    //     hardcode: !0
    //   });
    // }

    if (permission['BaoCaoGiamTru']) {
      if (id === '9379d710-24a5-48ac-9607-95486c67685c') {
        childrens.push({
          Checked: false,
          Id: 'giam-tru',
          IsParent: false,
          Level: 2,
          Name: 'Báo Cáo Giảm Trừ',
          ParentCount: 1,
          ParentId: _.noop(),
          Path: 'SERVICES/Báo Cáo Giảm Trừ',
          ReportGroups: _.noop(),
          TypeName: 'Custom',
          hardcode: !0
        });
      }
    }
    findMenu(result, id, childrens);
  };

  menudata.splice(2, 0, {
    Checked: false,
    Id: '7days',
    IsParent: true,
    Level: 1,
    Name: 'BC 7 ngày',
    ParentCount: 0,
    ParentId: '1b588945-b58e-455e-8d74-4f8ed188dba5',
    Path: 'BC 7 ngày',
    ReportGroups: _.noop(),
    TypeName: 'Custom',
    hardcode: !0
  });
  menudata.splice(3, 0, {
    Checked: false,
    Id: '30days',
    IsParent: true,
    Level: 1,
    Name: 'BC 30 ngày',
    ParentCount: 0,
    ParentId: '1b588945-b58e-455e-8d74-4f8ed188dba5',
    Path: 'BC 30 ngày',
    ReportGroups: _.noop(),
    TypeName: 'Custom',
    hardcode: !0
  });

  menudata.forEach((report) => {
    if (
      //Remove Help
      report.Id !== '667ab551-dab7-4005-ba09-f4a76be9cd47' &&
      report.ParentId !== '667ab551-dab7-4005-ba09-f4a76be9cd47' &&
      report.Id !== '0e609f1e-abee-4d7f-aa9d-511ed36b14de' &&
      report.ParentId !== '0e609f1e-abee-4d7f-aa9d-511ed36b14de'
    ) {
      if (
        report.ParentId === '1b588945-b58e-455e-8d74-4f8ed188dba5' &&
        result.filter((menu) => menu.Id === report.Id).length === 0
      ) {
        result.push(report);
      }
      getMenu(report.Id);
    }
  });
  return result;
};

export default HandlerDataMenu;
